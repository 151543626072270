$hoods-main-fonts: 'sofia-pro', 'Montserrat', 'Helvetica Neue', sans-serif;
$hoods-secondary-fonts: 'Playfair Display', 'Georgia', serif;
$hoods-alt-fonts: Roboto, 'Helvetica Neue', sans-serif;

$hoods-title-color: rgb(213, 62, 15);
$hoods-button-color: rgb(213, 62, 15);
$hoods-button-highlight: rgb(247, 202, 194);
$hoods-white-color: rgb(255, 255, 255);
$hoods-black-color: rgb(61, 61, 61);
$hoods-gray-color: rgb(112, 112, 112);

$hoods-body-background: #fcfcfc;

$hoods-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

// Responsive breaking points
$break-small: 768px;
$break-medium: 992px;
$break-tablet: 1024px;
$break-large: 1200px;

$mobile-map-height: 250px;

$divider-pattern: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ff7000' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");

/* You can add global styles to this file, and also import other style files */
html {
  max-width: 100%;
  text-rendering: geometricPrecision;
}

html,
body {
  height: 100%;
  background-color: $hoods-body-background;
}
body {
  margin: 0;
  font-family: $hoods-main-fonts;
  line-height: 200%;
  font-size: 16px;
  color: $hoods-black-color;
  //overflow-x: hidden;
  //min-width: 1000px;

  // Set the margin-top equal to main navbar height
  margin-top: 10vh;
}

button {
  cursor: pointer;
}

section {
  padding: 0 6em;

  @media screen and (max-width: $break-small) {
    padding: 0 3em;
  }
}

.tooltip-parent {
  cursor: help;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $hoods-button-color;
}

button:focus {
  outline: 0;
}

button.full-width,
.button.full-width {
  width: 100%;
  border-radius: 0;
  margin: 0;
}

.chips.toggle label {
  padding-left: 16px;

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    padding-right: 10px;
  }
  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    padding-left: 10px;
  }

  &.loading {
    padding-left: 36px;
  }
}

.chips {
  label {
    position: relative;
    padding-left: 35px;

    fa-icon {
      position: absolute;
      left: 10px;
      width: 20px;
      display: inline-block;
      text-align: center;
    }
  }
}

.nav-items a {
  font-size: 16px;
  text-transform: uppercase;
  color: $hoods-gray-color;

  &:hover {
    color: $hoods-button-color !important;
  }
}

h1,
h2,
h3 {
  color: $hoods-title-color;
}

.mat-toolbar {
  background-color: $hoods-white-color;
  color: $hoods-gray-color;
  border-bottom: 1px solid rgba($hoods-black-color, 0.2);
  height: 10vh;
}

mat-toolbar[_ngcontent-c0] .nav-items[_ngcontent-c0] a[_ngcontent-c0],
.mat-card {
  color: $hoods-gray-color;
  font-weight: normal;
  font-family: $hoods-main-fonts;
}

.mat-checkbox {
  margin-right: 1em;
}

.spinner {
  -webkit-animation: spin 4s infinite linear;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
.spinner-fast {
  -webkit-animation: spin 2s infinite linear;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}

summary {
  font-family: $hoods-main-fonts;
  font-weight: 400;
  font-style: italic;
  font-size: 1.5em;
}

hood map,
cities map,
municipality map {
  position: sticky !important;
  top: 11vh;
  display: block;

  @media screen and (max-width: $break-small) {
    position: relative !important;
    top: 0;
    display: block;
  }
}

.leaflet-marker-icon.leaflet-div-icon {
  background: $hoods-white-color;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
  border: 3px solid $hoods-button-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &.single {
    border: 0;
    background: $hoods-white-color;
    border: 3px solid $hoods-button-color;
  }

  .cluster {
    color: $hoods-black-color;
  }
  .lot-cluster {
    border-radius: 0%;
    color: $hoods-black-color;
  }
}

.lot-cluster {
  background: $hoods-white-color;
  width: 30px !important;
  height: 30px !important;
  border-radius: 0%;
  border: 3px solid $hoods-black-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: $hoods-title-color;
  display: flex !important;

  .lot-cluster-text {
    font-weight: bold;
    text-align: center;
  }
}

.leaflet-control-container {
  .leaflet-top {
    top: 1em;
  }

  .leaflet-right {
    right: 1em;
  }
}

mat-drawer-content {
  @media screen and (max-width: $break-small) {
    min-height: 80vh;
  }
}

// Hide leaflet layer control
.leaflet-control-layers {
  visibility: hidden;
}

.leaflet-control-maptoggle {
  font-size: 20px;
  background-color: $hoods-button-color;
  color: $hoods-white-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-layer-menu {
  // background-color: white;
}

button.filter-toggle {
  position: sticky;
  top: 0;
  margin-top: 1em;
  margin-left: -1em;
  margin-bottom: -5em;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 0;
  font-size: 1em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  z-index: 9999;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  padding: 8px 8px 8px 8px;
}

mat-drawer-container,
.mat-drawer-inner-container {
  overflow-x: visible !important;
}

mat-drawer-container button.filter-close {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  padding: 0;
  width: 4em;
  height: 4em;
  border-radius: 3px;
  border: 0;
  background-color: $hoods-button-color;
  color: $hoods-white-color;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

mat-toolbar .nav-items a.active {
  color: $hoods-button-color !important;
}

button.mat-button {
  color: $hoods-button-color;
}

.mat-dialog-actions {
  justify-content: flex-end;

  button.mat-button {
    font-family: $hoods-main-fonts;
    font-weight: bold;
    background-color: $hoods-button-color;
    color: $hoods-white-color;
    border: 2px solid $hoods-button-color;

    &.btn-cancel {
      border: 2px solid;
      background-color: $hoods-white-color;
      color: $hoods-button-color;
    }
  }
}

button.mat-flat-button,
a.button,
.leaflet-container a.button {
  background-color: $hoods-button-color;
  color: $hoods-white-color;
  margin-right: 0.5em;
  padding: 8px 20px;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  border: 2px solid $hoods-button-color;
  //increased letter-spacing for better readability
  letter-spacing: 0.5px;

  &.bordered {
    background-color: $hoods-white-color;
    border-color: $hoods-button-color;
    color: $hoods-button-color;
    border: 2px solid $hoods-button-color;

    .mat-button-wrapper {
      color: $hoods-button-color;
    }
  }

  &.borderless {
    background-color: transparent;
    border: 0;
    padding: 6px 18px;
    color: $hoods-button-color;
  }

  &.rounded {
    border-radius: 50px;
  }

  &:focus {
    border-color: $hoods-gray-color;
  }

  &:focus .mat-button-focus-overlay {
    background-color: transparent;
  }

  @media screen and (max-width: $break-small) {
    padding: 8px 16px;
    font-size: 12px;
  }
}

// Override angular material's mat-slide-toggle styles
mat-slide-toggle {
  &.mat-checked .mat-slide-toggle-bar {
    background-color: $hoods-button-highlight !important;
  }
  &.mat-checked .mat-slide-toggle-thumb {
    background-color: $hoods-button-color !important;
  }
  &.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
    background-color: $hoods-button-color !important;
  }
}

.mat-drawer {
  overflow-y: scroll;
  overflow-x: visible !important;
  padding-bottom: 0;
  height: 90vh;
}

search-filter {
  padding-bottom: 5em;
}

.mat-drawer-inner-container {
  //position: relative;
  overflow-y: visible !important;
  overflow-x: visible;
  margin-bottom: 10em;
  height: auto !important;
  background-color: $hoods-white-color;
}

.mat-drawer-content {
  height: 90vh;
  overflow-y: hidden;

  @media screen and (max-width: $break-small) {
    overflow-y: auto;
  }
}

// mat-accordion and mat-expansion-panel component styling

.mat-expansion-panel-header,
.mat-flat-button,
.mat-expansion-panel-body {
  font-family: $hoods-main-fonts !important;
}

.mat-expansion-panel-header-title {
  font-size: 1em;
  line-height: 1.5em;
  align-items: center;
  overflow-x: visible;
}

::ng-deep {
  .mat-expansion-indicator {
    display: flex;
  }
}

.mat-accordion > .mat-expansion-panel-spacing:last-child {
  margin-bottom: 1em;
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0.4em;
}

::ng-deep div.map {
  @media screen and (max-width: $break-small) {
    height: $mobile-map-height;
    z-index: 999; //'Show services' button and map navigation stays under main nav bar
  }
}

slideshow .slideshow-container {
  div.caption {
    text-align: right;
    width: 100vw;
    max-width: 100%;
    display: inline-block;
    right: 0 !important;
    padding-right: 32px !important;
    padding-left: 6px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    @media screen and (max-width: $break-small) {
      text-align: center;
      padding-right: 6px !important;
    }
  }

  .arrow-container.prev {
    padding-left: 1em;
  }
  .arrow-container.next {
    padding-right: 1em;
  }
}

.slideshow-container .slick-dots {
  top: 0px;
  //text-align: right !important;
}

.legend {
  & > span {
    margin-right: 0.5em;
    height: 1em;
    display: inline-block;

    .circle {
      display: inline-block;
      width: 1em;
      height: 1em;
      border-radius: 100%;
    }
  }
}

.fancy-divider {
  background-color: #fff;
  background-image: $divider-pattern;
  height: 34px;
  background-size: contain;
  background-position: center bottom;
  background-repeat: repeat-x;

  @media screen and (max-width: $break-small) {
    height: 20px;
  }
}

ngb-tooltip-window {
  z-index: 99999;
  font-size: inherit;
  font-weight: normal;
  //box-shadow: 0px 0px 4px rgba($hoods-gray-color, 0.5);
  filter: drop-shadow(0px 4px 4px rgba($hoods-gray-color, 0.5));

  &.bs-tooltip-top .arrow {
    left: calc(50% - 0.4rem);
    position: absolute;
    bottom: -8px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $hoods-white-color;
  }

  &.bs-tooltip-left .arrow {
    top: calc(50% - 0.4rem);
    position: absolute;
    right: -8px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid $hoods-white-color;
  }

  &.bs-tooltip-right .arrow {
    top: calc(50% - 0.4rem);
    position: absolute;
    left: -8px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid $hoods-white-color;
  }

  .arrow {
    box-sizing: content-box;
  }

  .tooltip-inner {
    max-width: 500px;
    padding: 0.5rem 0.5rem;
    color: $hoods-black-color;
    text-align: left;
    background-color: $hoods-white-color;
    border-radius: 0.25rem;
    font-size: 15px;
  }

  @media screen and (max-width: $break-small) {
    position: absolute !important;
    // top: 50vh !important;
    // left: 5vw !important; */
    // transform: translate(0, -50%) !important;
    transform: translateY(-50%) !important;
    width: 100%;
    top: initial !important;

    .arrow {
      display: none;
    }
  }
}

home {
  ngb-tooltip-window {
    @media screen and (max-width: $break-small) {
      position: fixed !important;
      transform: translateY(390%) !important;
      top: 0 !important;
    }
  }
}

.nudge {
  bottom: 130px !important;

  @media screen and (max-width: $break-small) {
    bottom: 16px !important;
  }
}

.feedback-bubble,
button.floating {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 54px;
  height: 54px;
  font-size: 24px;
  background-color: $hoods-button-color;
  z-index: 9999;
  color: $hoods-white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4);
}

button.floating.up {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  position: fixed;
  bottom: 16px;
  right: 16px + 54px + 16px;
  background-color: $hoods-button-color;
  color: $hoods-white-color;
  border: 0;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.feedback-box {
  background-color: $hoods-white-color;
  border-radius: 6px;
  position: fixed;
  bottom: 16px + 54px + 16px;
  right: 16px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4);
  width: 300px;
  max-height: 300px;
  padding: 1em;
  z-index: 9999;

  h3 {
    margin: 0;
  }

  button {
    border: 0;
    border-radius: 3px;
    padding: 0 16px;
  }

  button.close {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1em;
    right: 1em;
    padding: 0;
    font-weight: bold;
  }
}

button.close {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: bold;
  background: lighten($hoods-gray-color, 50);
  border: 0;
  border-radius: 3px;
}

.loading {
  text-align: center;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}
.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: $hoods-button-color;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: $hoods-button-color;
}
.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.notification {
  &.success {
    color: green;
  }

  &.alert {
    color: #e60000;
  }
}

::ng-deep .reply p {
  margin: 0;
}

// Range input styling

$thumb-height: 22px;
$thumb-width: 12px;
$thumb-border: 2px solid $hoods-gray-color;
$thumb-radius: 6px;
$track-height: 6px;
$track-bg: $hoods-button-color;
$track-border: 0;
$track-radius: 6px;

input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  // width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  border: 0;
  flex: 1;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: $thumb-border;
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: #ffffff;
    cursor: pointer;
    margin-top: -($thumb-height / 2) + ($track-height / 2); /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
  }

  input[type='range']::-moz-range-thumb {
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: $thumb-border;
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: #ffffff;
    cursor: pointer;
  }

  input[type='range']::-ms-thumb {
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: $thumb-border;
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: #ffffff;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: $track-height;
    cursor: pointer;
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: $track-bg;
    border-radius: $track-radius;
    border: $track-border;
  }

  &:focus::-webkit-slider-runnable-track {
    background: $track-bg;
  }

  &::-moz-range-track {
    width: 100%;
    height: $track-height;
    cursor: pointer;
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: $track-bg;
    border-radius: $track-radius;
    border: $track-border;
  }

  &::-ms-track {
    width: 100%;
    height: $track-height;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $track-bg;
    border: $track-border;
    border-radius: $track-radius;
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  &:focus::-ms-fill-lower {
    background: $track-bg;
  }

  &::-ms-fill-upper {
    background: $track-bg;
    border: $track-border;
    border-radius: $track-radius;
    // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  &:focus::-ms-fill-upper {
    background: $track-bg;
  }
}

.legend {
  font-size: 0.8em;
  margin-bottom: 1em;

  span {
    margin-right: 1em;
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 9999;
}

.field {
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $hoods-button-color;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $hoods-button-color;
  }

  .mat-radio-label {
    white-space: normal;
    line-height: 150%;
  }

  .mat-radio-group .mat-radio-button {
    margin-bottom: 0.5em;
  }

  .mat-radio-button,
  .mat-checkbox {
    font-family: inherit;
  }
}

div.slider .ng5-slider .ng5-slider-tick-legend {
  white-space: nowrap !important;
}

.leaflet-top.leaflet-left {
  z-index: 1001;
}

// Assistive styling
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// mat-form-field styling
form {
  .form-group {
    label {
      position: relative;
      bottom: -8px;
    }

    mat-checkbox {
      &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: rgb(236, 108, 48);
      }
    }

    mat-form-field {
      display: block;

      &.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
        position: relative;
      }
    }
  }
}

//info/users -page: settings for responsive iframe with max-width
.iframe-wrapper-1 {
  max-width: 720px !important;

  .iframe-wrapper-2 {
    position: relative !important;
    padding: 56.25% 0 0 0 !important; /* 16:9 */
    height: 0 !important;

    iframe {
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

::ng-deep {
  // Input is untouched i.e. has not been activated yet
  .ng-untouched .mat-form-field-outline {
    color: $hoods-gray-color;
  }
  // Input is focused i.e. cursor activated it the first time
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $hoods-gray-color;
  }
  // Hover over touched input field
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $hoods-gray-color;
  }
}

//resizing 'opens a new tab' icon
.external-link {
  font-size: 0.6rem;
  padding-left: 0.6em;
}

//cookiebot styling
#CybotCookiebotDialog {
  border: 10px solid #ffffff !important;
  color: $hoods-black-color !important;
}

#CybotCookiebotDialog div {
  font-family: sofia-pro, Montserrat, 'Helvetica Neue', sans-serif !important;
}

#CybotCookiebotDialogBodyContentTitle {
  color: $hoods-title-color !important; //#d53e0f
}

#CybotCookiebotDialogBodyContentText {
  font-weight: 400 !important;
}

.CybotCookiebotDialogBodyButton {
  min-width: 200px !important;
  border-radius: 4px !important;
}

.leaflet-messagebox {
  background-color: white;
  padding: 0.5em;
}

// Override leaflet's popup close button style
.leaflet-container a.leaflet-popup-close-button {
  top: 8px !important;
  right: 8px !important;
  font-weight: 100 !important;
  font-size: 180% !important;
  color: $hoods-black-color !important;
}

// Remove extra margin leaflet gives to popups
.service-marker .leaflet-popup-content {
  margin: 0;
}
.lot-marker .leaflet-popup-content {
  margin: 0;
}

.property-card,
.location-card {
  position: relative;
  width: 300px;
  font-size: 14px;

  .property-card-title,
  .location-card-title {
    // height: 2rem;
    display: flex;
    align-items: center;
    padding: 10px 16px !important;
    max-width: 80%;

    .card-title-text {
      margin-left: 0.8em;
      .card-title-name {
        font-weight: 600;
      }
    }
  }
  & > div:not(.card-divider, .card-divider-top, .card-content-wrapper) {
    padding: 8px 16px;
  }

  .card-content-wrapper {
    // Padding-top only
    padding: 0 0 8px 0;
  }

  .card-row {
    padding: 10px 16px;
    font-size: 12px;
  }

  .card-row .fa-external-link-alt {
    color: #cccccc;
  }
  .card-row-container {
    display: flex;
    align-items: center;

    &.url-container {
      a {
        width: 215px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .card-row.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-image {
    background-size: cover;
    background-position: center center;
    height: 8rem;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .card-description p {
    margin: 4px 0px;
  }

  .card-spacer {
    margin-top: 0.5rem;
  }
  .card-divider {
    overflow: auto;
    position: absolute;
    width: 100%;
    left: 0px;
    border-top: #cccccc solid 1px;
  }

  .card-hood-link {
    color: $hoods-button-color;
  }
  .card-link {
    color: $hoods-button-color;
    text-align: center;
    padding: 0.3rem;
    font-weight: 400;
  }
  .add-margin-bottom {
    margin-bottom: 1rem;
  }
}

.leaflet-popup {
  .leaflet-popup-content-wrapper {
    border-radius: 3px;
  }
  .leaflet-popup-tip-container {
    top: calc(100% - 1px);
  }
}

#feedbackPopup {
  display: flex;
  justify-content: center;
  &.hide-modal {
    .feedback-modal {
      display: none;
    }
  }
  .card-link {
    text-align: center;
    cursor: pointer;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .feedback-modal {
    position: absolute;
    top: -2%;
    left: -2%;
    width: 104%;
    height: auto;
    min-height: 104%;
    background-color: white;
    z-index: 1001;
    font-size: 14px;
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .header {
      padding: 10px 16px;
      border-bottom: 1px solid #cccccc;
    }
    .content {
      padding: 14px 16px;
    }
    h3 {
      margin: 0;
    }
    .closeFeedbackBtn {
      position: absolute;
      top: 6px;
      right: 12px;
      padding: 0;
      display: block;
      background-color: transparent;
      border: 0;
      font: 21px Tahoma, Verdana, sans-serif;
    }
    p {
      text-align: left;
      margin: 0px;
    }
    .card-btn {
      background-color: #d53d10;
      color: white;
      width: auto;
      font-weight: 600;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      span {
        margin-right: 0.5em;
      }
    }
  }
}

.full-screen-modal .mat-dialog-container {
  padding: 0;
  width: 100%;
  height: 100%;
}

.event-modal .mat-dialog-container {
  border-radius: 0px;
  padding: 0;
}

.popup-dialog-card {
  padding-bottom: 200px;
  .dialog-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    line-height: 150%;

    .dialog-title-text {
      display: flex;
      align-items: center;
      .dialog-title-name {
        font-weight: bold;
      }
      .dialog-title-type {
      }
    }
    .close-button {
      font-size: 160%;
    }
  }

  .dialog-row {
    // margin-bottom: 0.2rem;
  }
  .dialog-image {
    background-size: cover;
    background-position: center center;
    height: 180px;
  }
  .dialog-description {
    margin-bottom: 1rem;
  }
  .dialog-spacer {
    margin-top: 0.5rem;
  }
  .dialog-divider {
    overflow: auto;
    position: absolute;
    width: 100%;
    left: 0px;
  }
  .dialog-divider-top {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .dialog-hood-link {
    color: $hoods-button-color;
  }
  .dialog-link {
    font-size: 1.3em;
    color: $hoods-white-color;
  }
  .add-margin-bottom {
    margin-bottom: 1rem;
  }
  .go-to-button {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $hoods-button-color;
    height: 70px;
  }
  .dialog-content {
    padding: 1rem;
  }
}

// Overrides default Progress Bar
mat-progress-bar {
  @media screen and (max-width: $break-small) {
    height: 2px;
  }
  .mat-progress-bar-buffer {
    background: $hoods-button-color;
  }
  .mat-progress-bar-fill::after {
    background: $hoods-button-highlight;
  }
}

// Overrides default Select
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $hoods-button-color !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $hoods-button-color !important;
}

// Overrides date picker
.mat-calendar-body-selected {
  background-color: $hoods-button-color !important;
}

// Overrides Fab button color
.mat-fab.mat-accent {
  background-color: $hoods-button-color;
}

event-filter {
  .close-button {
    position: sticky;
    bottom: 0;
    button {
      width: 100%;
      &.mat-flat-button {
        height: 4rem;
        border-radius: 0;
        font-size: 140%;
        font-weight: normal;
        margin: 0;
      }
      &.mat-button {
        font-size: 100%;
        font-weight: normal;
        background-color: $hoods-white-color;
      }
    }
  }
}

// Override date picker
.mat-datepicker-content-touch {
  overflow: hidden !important;
}

// Override tabs
event-item-small,
event-item {
  .mat-tab-header {
    border-bottom: 0;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $hoods-button-color;
  }
  .mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    padding: 0;
  }

  // .mat-tab-body-wrapper {
  //   height: 100%;
  // }
}

.mat-select-panel {
  overflow: hidden !important;
}
// Fix search components select option list not fully rendering
.cdk-overlay-container {
  .mat-select-panel.mat-select-full-height {
    overflow: auto !important;
  }
}

// Fixes issue in search-result component
.hide-body-scroll {
  overflow: hidden !important;
}

.services-list-item-details-dialog .mat-dialog-container {
  padding: 0;
}

app-services-filter-mobile {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $hoods-button-color;
  }
  .mat-expansion-panel-body {
    padding: 0 0 16px 0 !important;
  }

  .mat-list-base .mat-list-item .mat-list-item-content-reverse,
  .mat-list-base .mat-list-option .mat-list-item-content-reverse {
    padding: 0 24px 0 0 !important;
  }

  .mat-list-base {
    padding-top: 0;
  }

  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate,
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $hoods-button-color;
  }

  .mat-expansion-panel-header-title {
    font-size: 16px !important;
    font-family: $hoods-alt-fonts !important;
  }
}

app-services-filter {
  button span.mat-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

app-services-list {
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $hoods-button-color;
  }
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $hoods-button-color;
  }
}

.simple-map-dialog .mat-dialog-container {
  padding: 0;
}

.service-list-item-snackbar {
  background-color: $hoods-white-color !important;
  color: $hoods-black-color !important;
  top: -80px !important;
  position: relative !important;
}

// Override mat tooltip
.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      color: $hoods-black-color;
      background-color: $hoods-white-color;
      box-shadow: $hoods-box-shadow;
      border-radius: 3px;
      font-size: 100%;
      padding: 10px;
    }
  }
}
